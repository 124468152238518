import { Record, List, fromJS } from "immutable";

import {
  ADD_TEACHERS,
  DESELECT_ALL_TEACHERS,
  DESELECT_TEACHERS,
  DOWNGRADE_USERS_SUCCESS,
  RECEIVE_TEACHER_DATA,
  REMOVE_TEACHERS,
  RESET_TEACHERS_STORE,
  SELECT_TEACHERS,
  SET_ASSIGNING_LICENSES,
  SET_CONFIRM_ASSIGN_LICENSES,
  SET_CONFIRM_REMOVE_LICENSES,
  SET_DOWNGRADING_USERS,
  SET_EMAIL_SENT,
  SET_EMAIL_TEMPLATE,
  SET_EMAILING_TEACHERS,
  SET_IMPORTING_TEACHERS,
  SET_REMOVING_LICENSES,
  SET_REMOVING_TEACHERS,
  SET_SHOW_TEACHER_IMPORT,
  SET_SORT_BY,
  SET_TEACHERS_TO_EMAIL,
  SET_TEACHERS_TO_REMOVE,
  SET_USER_ROLE,
  SET_USERS_TO_DOWNGRADE,
  TOGGLE_TEACHER_SELECTION,
  RECEIVE_INACTIVE_TEACHER_DATA,
  SET_TEACHERS_TO_DELETE,
  SET_DELETING_TEACHERS,
} from "./action-types";

import { SIGN_OUT_SUCCESS } from "../auth";

const defaultSortBy = "email.ASC";

export const TeachersState = new Record({
  assigningLicenses: false,
  defaultSortBy,
  downgradingUsers: false,
  emailingTeachers: false,
  emailSent: false,
  emailTemplate: null,
  importingTeachers: false,
  licensesToAssign: null,
  licensesToRemove: null,
  loaded: false,
  inactiveLoaded: false,
  removingLicenses: false,
  removingTeachers: false,
  selectedTeachers: new List(),
  showTeacherImport: false,
  sortBy: defaultSortBy,
  teachers: new List(),
  inactiveTeachers: new List(),
  teachersToEmail: null,
  teachersToRemove: null,
  usersToDowngrade: null,
  teacherToDelete: null,
  deletingTeacher: false,
});

export function teachersReducer(
  state = new TeachersState(),
  { payload, type }
) {
  switch (type) {
    case RECEIVE_TEACHER_DATA:
      const nextTeachers = payload ? payload : state.teachers;
      return state.merge({
        teachers: nextTeachers,
        loaded: true,
      });
    case RECEIVE_INACTIVE_TEACHER_DATA:
      const inactiveTeachers = payload ? payload : state.inactiveTeachers;
      return state.merge({
        inactiveTeachers: inactiveTeachers,
        inactiveLoaded: true,
      });
    case ADD_TEACHERS:
      let addedTeachers = state.teachers;
      payload.forEach(teacher => {
        addedTeachers = addedTeachers.push(fromJS(teacher));
      });
      return state.merge({
        teachers: addedTeachers,
      });
    case REMOVE_TEACHERS:
      return state.merge({
        teachers: state.teachers.filter(teacher => {
          return payload.indexOf(teacher.get("id")) === -1;
        }),
      });
    case SET_USER_ROLE:
      return state.merge({
        teachers: state.teachers.map(teacher => {
          if (teacher.get("id") === payload.id) {
            return teacher.set("role", payload.role);
          }
          return teacher;
        }),
      });
    case SET_SORT_BY:
      return state.merge({ sortBy: payload ? payload : state.defaultSortBy });
    case TOGGLE_TEACHER_SELECTION:
      let nextSelection;
      payload.forEach(teacherId => {
        const selectedIndex = state.selectedTeachers.indexOf(teacherId);
        if (selectedIndex > -1) {
          nextSelection = state.selectedTeachers.delete(selectedIndex);
        } else {
          nextSelection = state.selectedTeachers.push(teacherId);
        }
      });
      return state.merge({ selectedTeachers: nextSelection });
    case SELECT_TEACHERS:
      let selectedTeachers = state.selectedTeachers;
      payload.forEach(teacherId => {
        if (selectedTeachers.indexOf(teacherId) < 0) {
          selectedTeachers = selectedTeachers.push(teacherId);
        }
      });
      return state.merge({ selectedTeachers });
    case DESELECT_TEACHERS:
      let deselectedTeachers = state.selectedTeachers;
      payload.forEach(teacherId => {
        const i = deselectedTeachers.indexOf(teacherId);
        if (i > -1) {
          deselectedTeachers = deselectedTeachers.delete(i);
        }
      });
      return state.merge({ selectedTeachers: deselectedTeachers });

    case SET_CONFIRM_ASSIGN_LICENSES:
      return state.merge({ licensesToAssign: payload });
    case SET_ASSIGNING_LICENSES:
      return state.merge({ assigningLicenses: payload });
    case SET_CONFIRM_REMOVE_LICENSES:
      return state.merge({ licensesToRemove: payload });
    case SET_REMOVING_LICENSES:
      return state.merge({ removingLicenses: payload });
    case SET_SHOW_TEACHER_IMPORT:
      return state.merge({ showTeacherImport: payload });
    case SET_TEACHERS_TO_REMOVE:
      return state.merge({ teachersToRemove: payload });
    case SET_TEACHERS_TO_DELETE:
      return state.merge({ teacherToDelete: payload });
    case SET_DELETING_TEACHERS:
      return state.merge({ deletingTeacher: payload });
    case SET_IMPORTING_TEACHERS:
      return state.merge({ importingTeachers: payload });
    case SET_REMOVING_TEACHERS:
      return state.merge({ removingTeachers: payload });
    case DESELECT_ALL_TEACHERS:
      return state.merge({ selectedTeachers: new List() });
    case SET_TEACHERS_TO_EMAIL:
      return state.merge({ teachersToEmail: payload });
    case SET_EMAILING_TEACHERS:
      return state.merge({ emailingTeachers: payload });
    case SET_EMAIL_SENT:
      return state.merge({ emailSent: payload });
    case SET_EMAIL_TEMPLATE:
      return state.merge({ emailTemplate: payload });
    case SET_USERS_TO_DOWNGRADE:
      return state.merge({ usersToDowngrade: payload });
    case SET_DOWNGRADING_USERS:
      return state.merge({ downgradingUsers: payload });
    case DOWNGRADE_USERS_SUCCESS:
      return state.merge({
        teachers: state.teachers.filter(teacher => {
          return !payload.includes(teacher.get("id"));
        }),
      });

    case RESET_TEACHERS_STORE:
    case SIGN_OUT_SUCCESS:
      return new TeachersState();

    default:
      return state;
  }
}
