import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  teacherActions,
  getAllTeachers,
  getTeachersLoaded,
  getSelectedTeachers,
  getSortBy,
  getConfirmations,
  getShowTeacherImport,
  getImportingTeachers,
  getInactiveTeachers,
  getInactiveTeachersLoaded,
} from "../../../core/teachers";
import {
  getHasDomainLicense,
  getIsApplyToAllLicense,
  getLicenseInfo,
  getOrganisationId,
} from "../../../core/organisation";
import { getOrganisationFeatureFlags } from "../../../core/organisation";
import { getIsBookCreatorUser, getUserId, getEmail } from "../../../core/auth";
import { getAdmins, adminsActions } from "../../../core/admins";

import Loader from "../../components/ui/loader";
import Header, { HeaderActions } from "../../components/header";
import HeaderButton from "../../components/button/header";
import Content from "../../components/content";
import TeacherTable from "../../components/teachers/teacher-table";
import TeacherNav from "../../components/teachers/teacher-nav";
import TeacherConfirmations from "../../components/teachers/confirmations";
import EmailImport from "../../components/email-import";
import SearchBox from "../../components/forms/search-box";

function Teachers(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [searchText, setSearchText] = useState("");
  const [showInactiveTeachers, setShowInactiveTeachers] = useState(false);

  const {
    deselectAllTeachers,
    deselectTeachers,
    hasDomainLicense,
    isApplyToAllLicense,
    importingTeachers,
    importTeachers,
    isBookCreatorUser,
    adminUser,
    featureFlags,
    admins,
    licenseInfo,
    selectedTeachers,
    selectTeachers,
    setEmailTemplate,
    setLicensesToAssign,
    setLicensesToRemove,
    setShowTeacherImport,
    setSortBy,
    setTeachersToEmail,
    setTeachersToRemove,
    setUsersToDowngrade,
    showTeacherImport,
    sortBy,
    teachers,
    teachersLoaded,
    toggleLibraryManager,
    toggleTeacherSelection,
    loadTeachers,
    loadAdmins,
    reset,
    loadInactiveTeachers,
    grantMembership,
    inactiveTeachers,
    setTeacherToDelete,
    inactiveTeachersLoaded,
  } = props;

  useEffect(() => {
    loadTeachers();
    loadAdmins();
    window.addEventListener("resize", handleResize);
    return () => {
      reset();
      window.removeEventListener("resize", handleResize);
    };
  }, [loadTeachers, loadAdmins, reset]);

  useEffect(() => {
    if (showInactiveTeachers && !inactiveTeachersLoaded) {
      loadInactiveTeachers();
    }
  }, [showInactiveTeachers, loadInactiveTeachers, inactiveTeachersLoaded]);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const trimmedSearchText = searchText.trim();
  const showExportOnRight = windowWidth > 1150;
  const hasTeachers = teachers.size > 0;

  let filteredTeachers = showInactiveTeachers ? inactiveTeachers : teachers;

  if (trimmedSearchText.length > 0) {
    filteredTeachers = filteredTeachers.filter(t => {
      const email = t.get("email");
      const displayName = t.get("displayName");
      const searchable: string[] = [email, displayName].filter(v => v);
      const result = searchable.find(s =>
        s.toLowerCase().includes(trimmedSearchText.toLowerCase())
      );
      return result;
    });
  }

  return (
    <div>
      <Helmet title="Teacher dashboard" />
      <Header title="Teachers">
        {!hasDomainLicense && !showExportOnRight ? (
          <HeaderActions>
            <div id="page-nav-container" /> {/* Portal target */}
          </HeaderActions>
        ) : null}
        {!hasDomainLicense ? (
          <HeaderActions right>
            {showExportOnRight ? <div id="page-nav-container" /> : null}{" "}
            {/* Portal target */}
            <HeaderButton onClick={e => setShowTeacherImport(true)}>
              Import Teachers
            </HeaderButton>
          </HeaderActions>
        ) : (
          <HeaderActions right>
            <div id="page-nav-container" /> {/* Portal target */}
          </HeaderActions>
        )}
      </Header>
      <Content>
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <TeacherNav onChange={setShowInactiveTeachers} />
            <SearchBox
              value={searchText}
              onChange={value => setSearchText(value)}
              placeholder="Search by email or name"
              style={{ width: "265px" }}
            />
          </div>
          {!teachersLoaded ||
          (showInactiveTeachers && !inactiveTeachersLoaded) ? (
            <Loader colour="white" center />
          ) : (
            <TeacherTable
              showInactiveTeachers={showInactiveTeachers}
              teachers={filteredTeachers}
              hasTeachers={hasTeachers}
              selectedTeachers={selectedTeachers}
              licenseInfo={licenseInfo}
              sortBy={sortBy}
              setSortBy={setSortBy}
              selectTeachers={selectTeachers}
              deselectTeachers={deselectTeachers}
              deselectAllTeachers={deselectAllTeachers}
              hasDomainLicense={hasDomainLicense}
              isApplyToAllLicense={isApplyToAllLicense}
              isBookCreatorUser={isBookCreatorUser}
              adminUser={adminUser}
              featureFlags={featureFlags}
              admins={admins}
              toggleLibraryManager={toggleLibraryManager}
              toggleTeacherSelection={toggleTeacherSelection}
              setEmailTemplate={setEmailTemplate}
              setLicensesToAssign={setLicensesToAssign}
              setLicensesToRemove={setLicensesToRemove}
              setTeachersToRemove={setTeachersToRemove}
              setTeachersToEmail={setTeachersToEmail}
              setTeacherToDelete={setTeacherToDelete}
              setUsersToDowngrade={setUsersToDowngrade}
              grantMembership={grantMembership}
            />
          )}
        </>
      </Content>
      <TeacherConfirmations />
      {showTeacherImport ? (
        <EmailImport
          title="Import Teachers"
          onSubmit={teachers => importTeachers({ teachers })}
          importing={importingTeachers}
          assignLicenses
          userType="teachers"
          onClose={() => setShowTeacherImport(false)}
        />
      ) : null}
    </div>
  );
}

const adminUserProps = createSelector(
  getUserId,
  getEmail,
  getOrganisationId,
  getOrganisationFeatureFlags,
  getAdmins,
  (userId, email, orgId, featureFlags, admins) => ({
    adminUser: { userId, email, orgId },
    featureFlags,
    admins,
  })
);

const teachersProps = createSelector(
  getAllTeachers,
  getInactiveTeachers,
  getInactiveTeachersLoaded,
  getTeachersLoaded,
  getSelectedTeachers,
  getShowTeacherImport,
  getImportingTeachers,
  (
    teachers,
    inactiveTeachers,
    inactiveTeachersLoaded,
    teachersLoaded,
    selectedTeachers,
    showTeacherImport,
    importingTeachers
  ) => ({
    teachers,
    inactiveTeachers,
    inactiveTeachersLoaded,
    teachersLoaded,
    selectedTeachers,
    showTeacherImport,
    importingTeachers,
  })
);

const stateToProps = createSelector(
  getHasDomainLicense,
  getIsApplyToAllLicense,
  getIsBookCreatorUser,
  getSortBy,
  getConfirmations,
  getLicenseInfo,
  (
    hasDomainLicense,
    isApplyToAllLicense,
    isBookCreatorUser,
    sortBy,
    confirmations,
    licenseInfo
  ) => ({
    hasDomainLicense,
    isApplyToAllLicense,
    isBookCreatorUser,
    sortBy,
    confirmations,
    licenseInfo,
  })
);

const mapStateToProps = createSelector(
  teachersProps,
  stateToProps,
  adminUserProps,
  (teachersProps, stateToProps, adminUserProps) => ({
    ...teachersProps,
    ...adminUserProps,
    ...stateToProps,
  })
);

const mapDispatchToProps = {
  ...teacherActions,
  ...adminsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Teachers);
