import { BOOKCREATOR_ORGS_API_URL } from "../../config";

export async function getOrganisations(authedUserId: string) {
  const query = `query($sortBy: String) {
    getOrganisations(sortBy: $sortBy) {
      id name
    }
  }`;
  const data = { query, variables: { sortBy: "name.ASC" } };
  const response = await graphqlRequest(data, authedUserId);
  return await getResponsePayload(response, "getOrganisations");
}

export async function getOrganisationById(
  orgId: string,
  authedUserId: string,
  props?: string
) {
  const orgProps =
    props ||
    ` id name isDomain features licenses { id allocated quantity bookQuota libraryQuota applyToAll }`;
  const query = `query($orgId: OrgID!) {
    getOrganisationById(orgId: $orgId) {
      ${orgProps}
    }
  }`;
  const variables = { orgId };
  const data = { query, variables };

  const response = await graphqlRequest(data, authedUserId);
  return await getResponsePayload(response, "getOrganisationById");
}

export async function deleteMembership(
  orgId: string,
  memberIds: string[],
  authedUserId: string
) {
  const query = `mutation($orgId: OrgID!, $memberIds: [String!]!) {
    deleteMemberships(orgId: $orgId, memberIds: $memberIds) {
      orgId
    }
  }`;
  const variables = { orgId, memberIds };
  const data = { query, variables };

  const response = await graphqlRequest(data, authedUserId);
  return await getResponsePayload(response, "deleteMemberships");
}

export async function assignLicenses(
  orgId: string,
  memberIds: string[],
  authedUserId: string
) {
  const query = `mutation($orgId: OrgID!, $memberIds: [String!]!) {
    assignLicenses(orgId: $orgId, memberIds: $memberIds) {
      orgId
    }
  }`;
  const variables = { orgId, memberIds };
  const data = { query, variables };

  const response = await graphqlRequest(data, authedUserId);
  return await getResponsePayload(response, "assignLicenses");
}

export async function removeLicenses(
  orgId: string,
  memberIds: string[],
  authedUserId: string
) {
  const query = `mutation($orgId: OrgID!, $memberIds: [String!]!) {
    removeLicenses(orgId: $orgId, memberIds: $memberIds) {
      orgId
    }
  }`;
  const variables = { orgId, memberIds };
  const data = { query, variables };

  const response = await graphqlRequest(data, authedUserId);
  return await getResponsePayload(response, "removeLicenses");
}

export async function getOrganisationLicenses(
  orgId: string,
  authedUserId: string
) {
  return await getOrganisationById(
    orgId,
    authedUserId,
    "licenses { id allocated quantity bookQuota libraryQuota applyToAll }"
  );
}

export async function getOrganisationAdmins(
  orgId: string,
  authedUserId: string
) {
  return await getOrganisationById(orgId, authedUserId, "admins { email }");
}

export async function updateMembershipRole(
  orgId: string,
  userId: string,
  role: number,
  authedUserId: string
) {
  const query = `mutation($orgId: OrgID!, $userId: String!, $role: Int!) {
    updateMembershipRole(orgId: $orgId, userId: $userId, role: $role){
      userId
    }
  }`;

  const variables = { orgId, userId, role };
  const data = { query, variables };

  const response = await graphqlRequest(data, authedUserId);
  return await getResponsePayload(response, "updateMembershipRole");
}

export async function getOrganisationLTICredentials(
  orgId: string,
  authedUserId: string
) {
  return await getOrganisationById(
    orgId,
    authedUserId,
    "lti { consumerKey consumerSecret } "
  );
}

export async function getOrganisationSettings(
  orgId: string,
  authedUserId: string
) {
  return await getOrganisationById(orgId, authedUserId, "settings");
}

export async function updateSettings(
  orgId: string,
  settings: { [key: string]: number | null },
  authedUserId: string
) {
  const query = `mutation($orgId: OrgID!, $settings: Settings!) {
    updateSettings(orgId: $orgId, settings: $settings) {
      settings
    }
  }`;
  const variables = { orgId, settings };
  const data = { query, variables };

  const response = await graphqlRequest(data, authedUserId);
  return await getResponsePayload(response, "updateSettings");
}

export async function importAdmins(
  orgId: string,
  emails: string[],
  authedUserId: string
) {
  const query = `mutation($orgId: OrgID!, $emails: [EmailAddress!]!) {
    importAdmins(orgId: $orgId, emails: $emails) {
      orgId
    }
  }`;
  const variables = { orgId, emails };
  const data = { query, variables };

  const response = await graphqlRequest(data, authedUserId);
  return await getResponsePayload(response, "importAdmins");
}

export async function removeAdmins(
  orgId: string,
  emails: string[],
  authedUserId: string
) {
  const query = `mutation($orgId: OrgID!, $emails: [EmailAddress!]!) {
    deleteAdmins(orgId: $orgId, emails: $emails) {
      orgId
    }
  }`;
  const variables = { orgId, emails };
  const data = { query, variables };

  const response = await graphqlRequest(data, authedUserId);
  return await getResponsePayload(response, "deleteAdmins");
}

export async function generateLTICredentials(
  orgId: string,
  authedUserId: string
) {
  const query = `mutation($orgId: OrgID!) {
    generateLTICredentials(orgId: $orgId) {
      credentials { 
        consumerKey consumerSecret
      }
    }
  }`;
  const variables = { orgId };
  const data = { query, variables };

  const response = await graphqlRequest(data, authedUserId);
  return await getResponsePayload(response, "generateLTICredentials");
}

export async function revokeLTICredentials(
  orgId: string,
  authedUserId: string
) {
  const query = `mutation($orgId: OrgID!) {
    revokeLTICredentials(orgId: $orgId) {
      orgId
    }
  }`;
  const variables = { orgId };
  const data = { query, variables };

  const response = await graphqlRequest(data, authedUserId);
  return await getResponsePayload(response, "revokeLTICredentials");
}

export async function renameOrganisation(
  orgId: string,
  name: string,
  authedUserId: string
) {
  const query = `mutation($orgId: OrgID!, $name: String!) {
    renameOrganisation(orgId: $orgId, name: $name) {
      orgId
    }
  }`;
  const variables = { orgId, name };
  const data = { query, variables };

  const response = await graphqlRequest(data, authedUserId);
  return await getResponsePayload(response, "renameOrganisation");
}

export async function sendEmail(
  orgId: string,
  email: any,
  authedUserId: string
) {
  const query = `query($orgId: OrgID!, $email:EmailInput!) {
    sendEmail(orgId: $orgId, email: $email) {
      errors
    }
  }`;
  const data = { query, variables: { orgId, email } };
  const response = await graphqlRequest(data, authedUserId);
  return await getResponsePayload(response, "sendEmail");
}

export async function revokeMembership(
  orgId: string,
  memberIds: string[],
  authedUserId: string
) {
  const query = `mutation($orgId: OrgID!, $memberIds: [String!]!) {
    revokeMembership(orgId: $orgId, memberIds: $memberIds) {
      orgId
    }
  }`;
  const variables = { orgId, memberIds };
  const data = { query, variables };

  const response = await graphqlRequest(data, authedUserId);
  return await getResponsePayload(response, "revokeMembership");
}

export async function grantMembership(
  orgId: string,
  memberIds: string[],
  authedUserId: string
) {
  const query = `mutation($orgId: OrgID!, $memberIds: [String!]!) {
    grantMembership(orgId: $orgId, memberIds: $memberIds) {
      orgId
    }
  }`;
  const variables = { orgId, memberIds };
  const data = { query, variables };

  const response = await graphqlRequest(data, authedUserId);
  return await getResponsePayload(response, "grantMembership");
}

async function getResponsePayload(response, operationName) {
  if (response.errors) {
    const message =
      response.errors.map(err => err.message).join("\n") ||
      `Something went wrong with the operation ${operationName}`;
    return { ok: false, message };
  } else {
    const data = response.data[operationName];
    return { ok: true, data };
  }
}

async function graphqlRequest(data, authedUserId) {
  try {
    const response = await fetch(`${BOOKCREATOR_ORGS_API_URL}/graphql`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "x-bc-expected-user": authedUserId,
      },
    });
    return await response.json();
  } catch (err) {
    return { errors: [err] };
  }
}

export async function downloadStudentPortfolios(
  orgId: string,
  authedUserId: string
) {
  const response = await restRequest(
    `student-portfolios-export/${orgId}`,
    authedUserId
  );
  if (response.error) {
    switch (response.error.status) {
      case 404:
        response.error.message =
          "Portfolios are not enabled for this organisation";
        break;
      case 425:
        response.error.message = "No export yet generated";
        break;
    }
  }
  return getRestResponsePayload(response);
}

async function getRestResponsePayload(response, operationName = "") {
  if (response.error) {
    const message =
      response.error.message ||
      `Something went wrong with the operation ${operationName}`;
    return { ok: false, message, status: response.error.status };
  } else {
    return { ok: true, data: response };
  }
}

export async function restRequest(
  endpoint: string,
  authedUserId: string,
  method = "GET",
  data?: any
) {
  let apiResponse;
  try {
    const payload: RequestInit = {
      method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (data) {
      payload.body = JSON.stringify(data);
    }

    const response = await fetch(
      `${BOOKCREATOR_ORGS_API_URL}/rest/${authedUserId}/${endpoint}`,
      payload
    );
    if (response.ok) {
      apiResponse = await response.json();
    } else {
      apiResponse = {
        error: { message: response.statusText, status: response.status },
      };
    }
  } catch (error) {
    apiResponse = { error };
  }
  return apiResponse;
}
