import { createSelector } from "reselect";
import { getLicenseInfoJS } from "../organisation";

export function getTeachers(state) {
  return state.teachers;
}

export function getTeachersLoaded(state) {
  return getTeachers(state).loaded;
}

export function getInactiveTeachersLoaded(state) {
  return getTeachers(state).inactiveLoaded;
}

export function getAllTeachers(state) {
  return getTeachers(state).teachers;
}
export function getInactiveTeachers(state) {
  return getTeachers(state).inactiveTeachers;
}

export const getTeacherCount = createSelector(getAllTeachers, teachers => {
  return teachers.size;
});

export function getSortBy(state) {
  return getTeachers(state).sortBy;
}

export function getSelectedTeachers(state) {
  return getTeachers(state).selectedTeachers;
}

export const getSelectedTeachersWithData = createSelector(
  getAllTeachers,
  getSelectedTeachers,
  (allTeachers, selectedTeachers) => {
    return allTeachers
      .filter(teacher => selectedTeachers.contains(teacher.get("id")))
      .toJS();
  }
);

export function getShowTeacherImport(state) {
  return getTeachers(state).showTeacherImport;
}

export function getImportingTeachers(state) {
  return getTeachers(state).importingTeachers;
}

export function getTeachersToEmail(state) {
  return getTeachers(state).teachersToEmail;
}

export const getAllTeachersToEmailHave1000Books = createSelector(
  getAllTeachers,
  getTeachersToEmail,
  getLicenseInfoJS,
  (teachers, teachersToEmail, licenseInfo) => {
    if (!teachersToEmail) {
      return false;
    }
    const teachersWithData = teachers
      .filter(teacher => teachersToEmail.contains(teacher.get("id")))
      .toJS();
    if (licenseInfo && licenseInfo.length) {
      let license = licenseInfo.filter(license => license.bookQuota === 1000);
      let id1000Books;
      if (license.length) {
        id1000Books = license[0].id;
        return (
          teachersWithData.length > 0 &&
          teachersWithData.filter(teacher => teacher.license === id1000Books)
            .length === teachersWithData.length
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
);

export function getLicensesToAssign(state) {
  return getTeachers(state).licensesToAssign;
}

export const getTeachersToEmailAddresses = createSelector(
  getAllTeachers,
  getTeachersToEmail,
  (allTeachers, teachersToEmail) => {
    let addresses = [];
    if (!teachersToEmail) {
      return addresses;
    }
    teachersToEmail.forEach(teacherId => {
      const i = allTeachers.findIndex(teacher => {
        return teacher.get("id") === teacherId;
      });
      if (i > -1) {
        addresses.push(allTeachers.get(i).get("email"));
      }
    });
    return addresses;
  }
);

export const getTeachersToAssignCurrentLicenses = createSelector(
  getAllTeachers,
  getLicensesToAssign,
  (allTeachers, licensesToAssign) => {
    if (!licensesToAssign) return [];
    const userIds = licensesToAssign.get("userIds");
    const teachers = [];
    userIds.forEach(teacherId => {
      const t = allTeachers.findIndex(
        teacher => teacher.get("id") === teacherId
      );
      if (t > -1) {
        teachers[teacherId] = allTeachers.get(t).get("license");
      }
    });
    return teachers;
  }
);

export function getConfirmations(state) {
  const {
    licensesToAssign,
    assigningLicenses,
    licensesToRemove,
    removingLicenses,
    teachersToRemove,
    removingTeachers,
    teachersToEmail,
    emailingTeachers,
    emailSent,
    emailTemplate,
    usersToDowngrade,
    downgradingUsers,
    teacherToDelete,
    deletingTeacher,
  } = getTeachers(state);
  return {
    licensesToAssign,
    assigningLicenses,
    licensesToRemove,
    removingLicenses,
    teachersToRemove,
    removingTeachers,
    teachersToEmail,
    emailingTeachers,
    emailSent,
    emailTemplate,
    usersToDowngrade,
    downgradingUsers,
    teacherToDelete,
    deletingTeacher,
  };
}
