import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  teacherActions,
  getAllTeachersToEmailHave1000Books,
  getConfirmations,
  getTeachersToEmailAddresses,
  getTeachersToAssignCurrentLicenses,
} from "../../../../core/teachers";
import { getOrganisation } from "../../../../core/organisation";
import { getAuth } from "../../../../core/auth";
import AssignLicensesConfirmation from "./assign-licenses";
import RemoveTeachersConfirmation from "./remove-teachers";
import RemoveLicensesConfirmation from "./remove-licenses";
import WelcomeEmailConfirmation from "./welcome-email";
import DowngradeUserConfirmation from "./downgrade-user";
import DeleteTeachersConfirmation from "./delete-teachers";

class TeacherConfirmations extends Component {
  render() {
    const {
      allTeachersToEmailHave1000Books,
      confirmations,
      organisation,
      auth,
      setEmailTemplate,
      teachersToEmailAddresses,
      teachersToAssignCurrentLicenses,
    } = this.props;

    const {
      assigningLicenses,
      downgradingUsers,
      emailingTeachers,
      emailSent,
      emailTemplate,
      licensesToAssign,
      licensesToRemove,
      removingLicenses,
      removingTeachers,
      teachersToEmail,
      teachersToRemove,
      usersToDowngrade,
      teacherToDelete,
      deletingTeacher,
    } = confirmations;

    return (
      <div>
        {licensesToAssign ? (
          <AssignLicensesConfirmation
            licensesToAssign={licensesToAssign}
            assigningLicenses={assigningLicenses}
            teachersToAssignCurrentLicenses={teachersToAssignCurrentLicenses}
          />
        ) : null}
        {teachersToRemove ? (
          <RemoveTeachersConfirmation
            teachersToRemove={teachersToRemove}
            removingTeachers={removingTeachers}
          />
        ) : null}
        {licensesToRemove ? (
          <RemoveLicensesConfirmation
            licensesToRemove={licensesToRemove}
            removingLicenses={removingLicenses}
          />
        ) : null}
        {teacherToDelete ? (
          <DeleteTeachersConfirmation
            teacherToDelete={teacherToDelete}
            deletingTeacher={deletingTeacher}
          />
        ) : null}
        {teachersToEmail ? (
          <WelcomeEmailConfirmation
            allTeachersToEmailHave1000Books={allTeachersToEmailHave1000Books}
            emailTemplate={emailTemplate}
            teachersToEmail={teachersToEmail}
            teachersToEmailAddresses={teachersToEmailAddresses}
            emailingTeachers={emailingTeachers}
            emailSent={emailSent}
            adminName={auth.displayName}
            organisationName={organisation.get("name")}
            setEmailTemplate={setEmailTemplate}
          />
        ) : null}
        {usersToDowngrade ? (
          <DowngradeUserConfirmation
            usersToDowngrade={usersToDowngrade}
            downgradingUsers={downgradingUsers}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  getConfirmations,
  getOrganisation,
  getAuth,
  getTeachersToEmailAddresses,
  getTeachersToAssignCurrentLicenses,
  getAllTeachersToEmailHave1000Books,
  (
    confirmations,
    organisation,
    auth,
    teachersToEmailAddresses,
    teachersToAssignCurrentLicenses,
    allTeachersToEmailHave1000Books
  ) => ({
    confirmations,
    organisation,
    auth,
    teachersToEmailAddresses,
    teachersToAssignCurrentLicenses,
    allTeachersToEmailHave1000Books,
  })
);

const mapDispatchToProps = {
  ...teacherActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeacherConfirmations);
