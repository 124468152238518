import React from "react";
import TruncatedText from "../../../ui/truncated-text";
import SvgIcon from "../../../ui/svg-icon";
import StarIcon from "../../../../../images/svg/star.svg";
import ReactTooltip from "react-tooltip";
import Avatar from "../../../avatar";
import EyeIcon from "../../../../../images/svg/eye.svg";

import "./index.scss";

function TeacherName(props) {
  const {
    children,
    featureFlags,
    id,
    isAmbassador,
    isLibraryManager,
    avatarUrl,
    onAvatarClick,
    hideOverlayIcon,
  } = props;

  return (
    <div
      className="teacher-name fs-mask"
      style={{ paddingRight: isAmbassador ? 15 : null }}>
      <div className="teacher-name__avatar" onClick={onAvatarClick}>
        <Avatar
          id={`avatar-${id}`}
          name={children}
          isLibraryManager={isLibraryManager && featureFlags.orgLibraries}
          url={avatarUrl}
          size={36}
          overlayIcon={
            featureFlags.visitLibraries && !hideOverlayIcon ? (
              <SvgIcon icon={EyeIcon} name="eye" />
            ) : null
          }
        />
      </div>

      <TruncatedText className="teacher-name__label">{children}</TruncatedText>
      {isAmbassador ? (
        <span
          className="teacher-name__tooltip"
          data-tip
          data-for={`tooltip-${children}`}>
          <SvgIcon icon={StarIcon} name="star" />
          <ReactTooltip
            id={`tooltip-${children}`}
            effect="solid"
            multiline={false}>
            Book Creator Ambassador
          </ReactTooltip>
        </span>
      ) : null}
    </div>
  );
}

export default TeacherName;
